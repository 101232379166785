import React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { parseNestedHTML } from "../Utils";
import Slider from "react-slick";
import { Link, graphql, useStaticQuery } from "gatsby";
import HTMLReactParser from "html-react-parser";
import AddressAndTransport from "../components/AddressAndTransport";
import SafeHtmlParser from "../components/safe-html-parser";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const MiniNinja = () => {
	const data = useStaticQuery(graphql`
		query {
			wpMiniEvent {
				miniEventFields {
					address {
						btnText
						location {
							lat
							lng
						}
						title
					}
					facilities {
						btn1Text
						btn2Text
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					heroSections {
						btnText
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					needToKnow {
						info {
							icon {
								node {
									sourceUrl
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							subheading
							title
						}
						title
					}
					ninjaParties {
						btn1Text
						btn2Text
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						title
					}
					underFiveSession {
						body
						btnText
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						subheading
						title
					}
					vehicleStauts {
						body
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const MiniEventPage = data.wpMiniEvent.miniEventFields;
	const seoFields = data.wpMiniEvent.seoFields;
	console.log(MiniEventPage);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Mini Ninjas",
				item: {
					url: `${siteUrl}/mini-ninjas`,
					id: `${siteUrl}/mini-ninjas`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/mini-ninjas`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={MiniEventPage.heroSections.title}
												/>
											</div>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
												className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
											>
												{MiniEventPage.heroSections.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="slider-container">
							<Slider {...settings}>
								{MiniEventPage.heroSections.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="g-5 align-items-center">
							<Col lg={6}>
								<h2 className="display-5 pb-4 text-uppercase">
									{MiniEventPage.underFiveSession.title}
								</h2>
								<p className="text-white">
									{MiniEventPage.underFiveSession.subheading}
								</p>
								<div>
									<SafeHtmlParser
										htmlContent={MiniEventPage.underFiveSession.body}
									/>
								</div>
								<Button
									target="_blank"
									rel="noreferrer"
									href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
									className="text-white text-uppercase mt-4 w-100 w-md-auto fs-5 px-4 py-2"
								>
									{MiniEventPage.underFiveSession.btnText}
								</Button>
							</Col>
							<Col lg={{ span: 6, order: "last" }}>
								<div className="slider-container">
									<Slider {...settings}>
										{MiniEventPage.underFiveSession.slider.map((slider, i) => (
											<div key={i}>
												<GatsbyImage
													image={
														slider.image?.node.localFile?.childImageSharp
															?.gatsbyImageData
													}
													alt={slider.image?.node?.altText}
												/>
											</div>
										))}
									</Slider>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 pb-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-4 text-center text-uppercase">
									{MiniEventPage.needToKnow.title}
								</h2>
							</Col>
						</Row>
						<Row>
							<Col>
								<Row className="g-3">
									{MiniEventPage.needToKnow.info.map(
										({ icon, title, subheading }, i) => (
											<Col lg={4}>
												<Stack
													className="justify-content-start align-items-center text-center h-100 p-4"
													key={i}
													style={{
														backgroundColor: "#181819",
														borderRadius: 8,
													}}
												>
													<div
														className=" d-inline-block mb-4"
														style={{
															minWidth: "70px",
															width: "70px",
															maxHeight: "70px",
														}}
													>
														<img
															src={icon?.node?.sourceUrl}
															alt={icon?.node?.altText}
															className="w-100 mh-100"
														/>
													</div>

													<h3>{title}</h3>
													<p className="pb-0 mb-0">{subheading}</p>
												</Stack>
											</Col>
										)
									)}
								</Row>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-5 py-lg-7">
					<Row>
						<Col md={6} className="position-relative p-0">
							<div
								style={{
									position: "relative",
								}}
							>
								<div
									className="position-absolute top-0 start-0 end-0 bottom-0"
									style={{
										background:
											"linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)",

										zIndex: 1,
									}}
								/>
								<GatsbyImage
									image={
										MiniEventPage.ninjaParties.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									className="w-100 "
									style={{ objectFit: "cover" }}
									alt={MiniEventPage.ninjaParties.image?.node?.altText}
								/>
								<div
									style={{ zIndex: 2 }}
									className="position-absolute buttons-in-square w-100 w-lg-auto w-lg-auto px-5 px-md-4 px-lg-0 top-50 start-50 translate-middle"
								>
									<div className="stylishText text-uppercase session-heading">
										<SafeHtmlParser
											htmlContent={MiniEventPage.ninjaParties.title}
										/>
									</div>
									<p className="text-white">
										{MiniEventPage.ninjaParties?.subheading}
									</p>

									<Button
										variant="white"
										as={Link}
										to="/ninja-birthday-parties"
										className="text-primary text-uppercase me-3 mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
									>
										{MiniEventPage.ninjaParties.btn1Text}
									</Button>
									<Button
										variant="primary"
										as={Link}
										to="/ninja-birthday-parties#form"
										className="text-white text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
									>
										{MiniEventPage.ninjaParties.btn2Text}
									</Button>
								</div>
							</div>
						</Col>
						<Col md={6} className="position-relative p-0">
							<div
								style={{
									position: "relative",
								}}
							>
								<div
									className="position-absolute top-0 start-0 end-0 bottom-0"
									style={{
										background:
											"linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)",

										zIndex: 1,
									}}
								/>
								<GatsbyImage
									image={
										MiniEventPage.facilities.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									className="w-100 "
									style={{ objectFit: "cover" }}
									alt={MiniEventPage.facilities.image?.node?.altText}
								/>
								<div
									style={{ zIndex: 2 }}
									className="position-absolute buttons-in-square w-100 w-lg-auto w-lg-auto px-5 px-md-4 px-lg-0 top-50 start-50 translate-middle"
								>
									<div className="stylishText text-uppercase session-heading">
										<SafeHtmlParser
											htmlContent={MiniEventPage.facilities.title}
										/>
									</div>
									<p className="text-white">
										{MiniEventPage.facilities?.subheading}
									</p>

									<Button
										variant="white"
										as={Link}
										to="/facilities-and-accessibility"
										className="text-primary text-uppercase me-3 mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
									>
										{MiniEventPage.facilities.btn1Text}
									</Button>
									<Button
										variant="primary"
										target="_blank"
										rel="noreferrer"
										href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
										className="text-white text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
									>
										{MiniEventPage.facilities.btn2Text}
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				</section>
				<section className="pb-5 pb-lg-7">
					<AddressAndTransport
						address={MiniEventPage.address}
						vehiclesStatus={MiniEventPage.vehicleStauts}
					/>
				</section>
			</Layout>
		</div>
	);
};

export default MiniNinja;
